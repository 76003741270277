import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './login/login';
import SisLayout from './pages/layout';
import Dashboard from './pages/dashboard';
import RoleList from './pages/roles/roleList';
import { useEffect, useState } from 'react';
import { CHECK_TOKEN } from './apis/apis';
import { Calendar, ConfigProvider, Spin } from 'antd';
import AddRole from './pages/roles/addRole';
import EditRole from './pages/roles/editRole';
import UsersList from './pages/users/usersList';
import AddUser from './pages/users/addUser';
import EditUser from './pages/users/editUser';
import CategoriesList from './pages/categories/categoriesList';
import AddCategory from './pages/categories/addCategory';
import EditCategory from './pages/categories/editCategory';
import CoursesList from './pages/courses/coursesList';
import AddCourse from './pages/courses/addCourse';
import EditCourse from './pages/courses/editCourse';
import SisProgrammeView from './pages/programs/SectionList';
import ProgrammeList from './pages/programs/programsList';
import CalendarList from './pages/calendar/calendars';
import AddCalendar from './pages/calendar/calendar-curd/addCalendar';
import EditCalendar from './pages/calendar/calendar-curd/editCalendar';
import SisProgrammeAdd from './pages/programs/component/SisProgrammeAdd';
import SisProgrammeEdit from './pages/programs/component/SisProgrammeEdit';
import AddSemester from './pages/calendar/semester/addSemester';
import EditSemester from './pages/calendar/semester/editSemester';


import EnrollmentsList from './pages/enrollments/enrollmentsList';
import AddEnrollment from './pages/enrollments/addEnrollment';
import EditEnrollment from './pages/enrollments/editEnrollment';

import FacultiesList from './pages/faculties/facultiesList';
import AddFaculty from './pages/faculties/addFaculty';
import EditFacult from './pages/faculties/editFaculty';


import RoomsList from './pages/rooms/roomsList';
import AddRoom from './pages/rooms/addRoom';
import EditRoom from './pages/rooms/editRoom';
import SemesterCourses from './pages/calendar/semester-course/SemesterCourses';
import EditStudent from './pages/students/EditStudent';
import StudentsList from './pages/students/StudentsList';
import AddStudent from './pages/students/AddStudent';
import EmergencyContact from './pages/students/EmergencyContact';
import StudentDocuments from './pages/students/StudentDocuments';
import SemesterRegistration from './pages/semester-registration/SemesterRegistration';
import SemesterRegistrationView from './pages/semester-registration/SemesterRegistrationView';
import SemesterRegistrationStudent from './pages/semester-registration/SemesterRegistrationStudent';

import SetPassword from './set_password/SetPassword';
import SetUserPassword from './set_password/SetUserPassword';
import CalendarSchedule from './pages/Calendar-Schedule/CalendarSchedule';
import SemesterList from './pages/calendar/semester/semesterList';
import Studentstally from './pages/students-listing/listing/Studentstally';
import StudentsDetails from './pages/students-listing/listing/Studentsdetails';
import EditStudentPopup from './pages/students-listing/listing/EditStudentPopup';
import Academic from './pages/students-listing/academic/Academic';
import ResetPassword from './set_password/ResetPassword';


// Student New Routes Import 



// import StudentsProfileDetails from './pages/profile/studentProfile';
import StudentsProfileDetails from './pages/myProfile/studentProfile';
import MyAcademic from './pages/myAcademics/studentAcademics';
// import MyGrade from './pages/myGrades/studentGrades';
import Editpopup from './pages/myProfile/Editpopup';
import StudentGradesView from './pages/myGrades/studentGradesView';
import StudentOnlineApplication from './pages/Student-Online-Registration/Online-Enrollment-Form/studentOnlineApplication';
import SelfRegistrationLayout from './pages/Student-Online-Registration/Layout/selfRegistrationLayout';
import ShowPrivacyPolicy from './pages/Student-Online-Registration/Create-Account/showPrivacyPolicy';
import EnrollmentApplicationFormLayout from './pages/Student-Online-Registration/Layout/enrollmentApplicationFormLayout';
import DemographicInformation from './pages/Student-Online-Registration/Online-Enrollment-Form/demographicInformation';
import PersonalInformation from './pages/Student-Online-Registration/Online-Enrollment-Form/personalInformation';
import Agreement from './pages/Student-Online-Registration/Online-Enrollment-Form/agreement';
import FinanceDeclaration from './pages/Student-Online-Registration/Online-Enrollment-Form/financeDeclaration';
import OnlineFeePayment from './pages/Student-Online-Registration/Online-Enrollment-Form/onlineFeePayment';
import FormPreview from './pages/Student-Online-Registration/Online-Enrollment-Form/formPreview';
import SelfSemesterRegistration from './pages/Self-Semester-Registration/selfSemesterRegistration';
import AcademicPaymentPlan from './pages/myAcademics/Modals/academics_payment_plan';
import AcademicsBilling from './pages/myAcademics/Billing/academics_billing';
import BillingPaymentPlan from './pages/myAcademics/Modals/billing_payment_plan';
import DropCourse from './pages/myAcademics/DropCourse/dropCourse';
import DropCourseBilling from './pages/myAcademics/DropCourse/addDropCourseBilling';
import WithdrawFromSemeter from './pages/myAcademics/WithdrawFromSemester/withdrawFromSemeter';
import ChooseAddDropCourse from './pages/myAcademics/DropCourse/chooseAddDropCourse';
import AddCourseBilling from './pages/myAcademics/AddCourse/addCourseBilling';
import ChooseAddCourse from './pages/myAcademics/AddCourse/chooseAddCourse';
import AddDropCourseBilling from './pages/myAcademics/DropCourse/addDropCourseBilling';
import ViewStudentAcademics from './pages/myAcademics/ViewMyAcademics/viewStudentAcademics';
import AnotherViewStudentsGrades from './pages/myGrades/ViewMyGrades/anotherViewStudentsGrades';






function App() {
  const [student_login_check, set_student_login_check] = useState(false);
  const [self_register_login_check, set_self_register_login_check] = useState(false);
  const [loader, set_loader] = useState(true);
  const[user_data , set_user_data] = useState("");
  const[system_setting , set_system_setting] = useState("");
  const CHECK_TOKEN_API = async () => {
    try {
      const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
      const FORM_DATA = new FormData();
      FORM_DATA.append('token', USER_DATA.token);
      FORM_DATA.append('user_type', USER_DATA.user_type);
      const CHECK_TOKEN_RESPONSE = await CHECK_TOKEN(FORM_DATA);
      if (CHECK_TOKEN_RESPONSE?.data?.status) {

        if(USER_DATA.user_type==="student")
          {
            set_student_login_check(true);
            set_self_register_login_check(false);
            set_loader(false);
          
          }

          if(USER_DATA.user_type==="self_registration")
            {
              
              set_self_register_login_check(true);
              set_student_login_check(false);
            
              set_loader(false);
            }
       
      } else {
        localStorage.clear();
        set_student_login_check(false);
        set_self_register_login_check(false);
        set_loader(false);
      }
    } catch (error) {
      localStorage.clear();
      set_student_login_check(false);
      set_self_register_login_check(false);
      set_loader(false);
    }
  }
  useEffect(() => {
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const SYSTEM_SETTING = JSON.parse(localStorage.getItem('sis_system_setting'));
    set_system_setting(SYSTEM_SETTING);
    CHECK_TOKEN_API();
    set_user_data(USER_DATA)
  }, []);
  return (
    <>
      {loader ? <>
        <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
          <Spin />
        </div>
      </> :
        <>
        <ConfigProvider
        theme={{
        token:{
          colorPrimary : "#DFA414"
        }
      }}
      >

          <BrowserRouter>
            <Routes>
              {student_login_check ?
                <Route path="/" element={<SisLayout />}>
                   <Route index element={<StudentsProfileDetails />} />
                  {/* <Route index element={<Dashboard />} /> */}

                  {/* Roles */}
                  <Route path='/role-list' element={<RoleList />} />
                  <Route path='/add-role' element={<AddRole />} />
                  <Route path='/edit-role/:id' element={<EditRole />} />


                  {/* UsersList */}
                  <Route path='/users-list' element={<UsersList />} />
                  <Route path='/add-user' element={<AddUser />} />
                  <Route path='/edit-user/:id' element={<EditUser />} />

                  {/* StudentsList */}
                  <Route path='/registration-list' element={<StudentsList />} />
                  <Route path='/add-registration' element={<AddStudent />} />
                  <Route path='/edit-registration/' element={<EditStudent />} />
                  <Route path='/emergency-contact/:id' element={<EmergencyContact />} />
                  <Route path='/registration-documents/:id' element={<StudentDocuments />} />


                  {/* CategoriesList */}
                  <Route path='/categories-list' element={<CategoriesList />} />
                  <Route path='/add-category' element={<AddCategory />} />
                  <Route path='/edit-category/:id' element={<EditCategory />} />

                  {/* CategoriesList */}
                  <Route path='/courses-list' element={<CoursesList />} />
                  <Route path='/add-course' element={<AddCourse />} />
                  <Route path='/edit-course/:id' element={<EditCourse />} />

                  {/* Programs  */}
                  <Route path='/programmes-list' element={<ProgrammeList />} />
                  <Route path='/add-programme' element={<SisProgrammeAdd />} />
                  <Route path='/edit-programme/:id?' element={<SisProgrammeEdit />} />
                  <Route path='/section/:id?' element={<SisProgrammeView />} />
                  {/* <Route path='/programmes-list' element={<ProgrammeList />} />
                  <Route path='/programme/:id?' element={<SisProgrammeView />} /> */}


                  {/* Calendar  */}
                  <Route path='/calendar-list' element={<CalendarList />} />
                  <Route path='/calendar-schedule/:calendar_id' element={<CalendarSchedule />} />
                  <Route path='/add-calendar' element={<AddCalendar />} />
                  <Route path='/calendar-semester/:calendar_id' element={<SemesterList />} />
                  <Route path='/edit-calendar/:id' element={<EditCalendar />} />

                  {/* Calendar  */}
                  <Route path='/add-semester/:id' element={<AddSemester />} />
                  <Route path='/edit-semester/:id/:sem_id' element={<EditSemester />} />
                  <Route path='/semester-courses/:id/:sem_id?' element={<SemesterCourses />} />

                  {/* EnrollmentsList */}
                  <Route path='/enrollments-list' element={<EnrollmentsList />} />
                  <Route path='/add-enrollment/:id' element={<AddEnrollment />} />
                  <Route path='/edit-enrollment/:id' element={<EditEnrollment />} />

                  {/*  Faculty List */}
                  <Route path='/facultiesList' element={<FacultiesList />} />
                  <Route path='/add-faculty' element={<AddFaculty />} />
                  <Route path='/edit-faculty/:id' element={<EditFacult />} />

                  {/* RoomsList */}
                  <Route path='/rooms-list' element={<RoomsList />} />
                  <Route path='/add-room' element={<AddRoom />} />
                  <Route path='/edit-room/:id' element={<EditRoom />} />


                  {/* RoomsList */}
                  <Route path='/semester-registration' element={<SemesterRegistration />} />
                  <Route path='/semester-registration-view/:id/:sem_id?' element={<SemesterRegistrationView />} />
                  <Route path='/semester-registration-student/:id/:sem_id/:student_id' element={<SemesterRegistrationStudent />} />

  
                  <Route path='/students-list' element={<Studentstally />} />
                   <Route path='/edit-student-model/:id' element={<EditStudentPopup />} />
                   <Route path='/student-academy/:id' element={<Academic />} />

                   
                   {/* Students Panel Routes Listing Profile Route */
                    // Started by Hitesh and Shubham on 21 May              
                   }

                   {/* <Route path='/students-profile-detail' element={<StudentsProfileDetails/>} /> */}
                   <Route path='/editpopup' element={<Editpopup/>} />

                   {system_setting?.student_toggle_show ?
                   <>
                   <Route path='/students-academic-detail' element={<MyAcademic/>} />
                   <Route path='/students-grade-detail' element={<StudentGradesView/>} />

                   {/* self-semester-registration */}
                   <Route path='/self-semester-registration/:sem_id' element={<SelfSemesterRegistration/>} />
                   <Route path='/view-academics-payment-plan' element = {<AcademicPaymentPlan/>} />
                   <Route path='/academics-billing/:sem_id/:calendar_id' element = {<AcademicsBilling/>} />
                   <Route path='/view-billing-payment-plan' element = {<BillingPaymentPlan/>} />

                    {/* add  Drop and Withdraw */}
                    <Route path='/add-course/:sem_id' element = {<ChooseAddCourse/>} />
                    <Route path='/add-course-billing/:sem_id/:std_id' element = {<AddCourseBilling/>} />
                    <Route path='/add-drop-course/:sem_id' element = {<ChooseAddDropCourse/>} />
                    <Route path='/drop-course/:c_id' element = {<DropCourse/>} />
                    <Route path='/add-drop-course-billing/:sem_id/:std_id' element = {<AddDropCourseBilling/>} />
                    <Route path='/withdraw-semester' element = {<WithdrawFromSemeter/>} />

                    </>
                    :
                    <>
                     <Route path='/students-academic-detail' element={<ViewStudentAcademics/>} />
                     <Route path='/students-grade-detail' element={<AnotherViewStudentsGrades/>} />
                    </>
                    }


                   {/* End of list of Students Panel Routes Listing Profile Route */
                    // Started by Hitesh and Shubham on 21 May   
                  }



                  {/* <Route path='/add-room' element={<AddRoom />} />
                  <Route path='/edit-room/:id' element={<EditRoom />} /> */}

                </Route>
                :
                <>
                {self_register_login_check ?
                 <>
                 {user_data?.payment_status ? <>
                  <Route path='/' element={<EnrollmentApplicationFormLayout />}>
                  <Route index element={<DemographicInformation/>}/>
                  {/* <Route path='/demographic-information' element={<DemographicInformation />}/> */}
                  <Route path='/payment' element={<OnlineFeePayment/>}/>
                  <Route path='/formpreview' element={<FormPreview/>}/>
                  </Route> 
                 
                 </> : <>
                 <Route path='/' element={<EnrollmentApplicationFormLayout />}>
                  <Route index element={<PersonalInformation/>}/>
                  <Route path='/demographic-information' element={<DemographicInformation />}/>
                  <Route path='/agreement' element={<Agreement />}/>
                  <Route path='/finance-declaration' element={<FinanceDeclaration/>}/>
                  <Route path='/payment' element={<OnlineFeePayment/>}/>
                  <Route path='/formpreview' element={<FormPreview/>}/>
                  </Route> 
                 </>}
                 </>
                
                  :
                  <Route path="/" element={<Login />} />
                }
                </>
              }
              <Route path='/reset-password' element={<ResetPassword />} />
              <Route path='/set-new-password/:user_type/:type/:token' element={<SetPassword />} />
              <Route path='/set-user-password/:type/:token' element={<SetUserPassword />} />

              {/* student self Registration */}

              <Route path='/student-self-online-registration' element={<SelfRegistrationLayout />}/>
              <Route path='/show-privacy-policy' element={<ShowPrivacyPolicy/>}/>
              
            </Routes>
          </BrowserRouter>
          </ConfigProvider>
        </>}
    </>

  );
}

export default App;
