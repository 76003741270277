import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Input, Result, Spin } from 'antd';
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { STRIPE_PAYMENT_COUPON, STRIPE_PAYMENT_INTENT_SERVICE, STRIPE_REGISTRATION_CHECKOUT } from '../../../../apis/apis';
import SisLoader from '../../../../widgets/loader';
import { json, useNavigate } from 'react-router-dom';
import { CountryDropdown, CountryRegionData} from "react-country-region-selector";
import { type } from '@testing-library/user-event/dist/type';

const CheckoutForm = ({ set_program, set_enrollment, set_loader, set_view_amount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, set_name] = useState("");
  const [loaderCoupon, set_loaderCoupon] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submit_loading, set_submit_loading] = useState(false);
  const [card_error, set_card_error] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [apply_coupon, set_apply_coupon] = useState(true);
  const [remove_coupon, set_remove_coupon] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [isCardFocused, setIsCardFocused] = useState(false);
  const [amount, set_amount] = useState('');
  const [payment_intent, set_payment_intent] = useState('');
  const [payment_amount, set_payment_amount] = useState('');
  const [payment_status, set_payment_status] = useState('');
  const [payment_row, set_payment_row] = useState('');
  const [response_json, set_response_json] = useState('');
  const [address1, set_address1] = useState("");
  const [address2, set_address2] = useState("");
  const [address_city, set_address_city] = useState("");
  const [address_state, set_address_state] = useState("");
  const [address_postal, set_address_postal] = useState("");
  const [address_country, set_address_country] = useState("");
  const navigate = useNavigate();

  const [errors, set_errors] = useState({});

  const VIEW_STRIPE_PAYMENT_INTENT_SERVICE = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('student_user_id', USER_DATA.id);
    const VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE = await STRIPE_PAYMENT_INTENT_SERVICE(FORM_DATA);
    if (VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE?.data?.status) {
      localStorage.setItem("payment_status", (VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE?.data?.data?.payment_status == true) ? 1 : 0);
      set_view_amount(VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE?.data?.data?.amount);
      set_amount(VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE?.data?.data?.amount);
      set_payment_intent(VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE?.data?.data?.paymentIntent);
      set_payment_amount(VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE?.data?.data?.payment_amount);
      set_payment_status(VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE?.data?.data?.payment_status);
      set_program(VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE?.data?.data?.program);
      set_enrollment(VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE?.data?.data?.enrollment);
      if (VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE?.data?.data?.payment_status) {
        set_payment_row(VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE?.data?.data?.payment_row);
        set_response_json(JSON.parse(VIEW_STRIPE_PAYMENT_INTENT_SERVICE_API_RESPONSE?.data?.data?.payment_row.response_json));
      }

      set_loader(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    VIEW_STRIPE_PAYMENT_INTENT_SERVICE();
  }, [])

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   set_submit_loading(true);
  //   set_card_error("");

  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   const cardElement = elements.getElement(CardElement);

  //   const clientSecret = payment_intent?.client_secret;

  //   const result = await stripe.confirmCardPayment(clientSecret, {
  //     payment_method: {
  //       card: cardElement,
  //       billing_details: {
  //             name: `${first_name} ${middle_name} ${last_name}`,
  //             address: {
  //             line1: address1,
  //             line2: address2,
  //             city: address_city,
  //             state: address_state,
  //             postal_code: address_postal,
  //             country: address_country
  //         }
  //     }
  //     }
  //   });

  //   if (result.error) {
  //     set_card_error(result.error.message)
  //     console.error(result.error.message);
  //   } else {
  //     set_card_error("")
  //     console.log('line 68', result);
  //     if (result.paymentIntent.status === 'succeeded') {
  //       console.log('Payment succeeded!');
  //       CALL_STRIPE_REGISTRATION_CHECKOUT();
  //       // here we call checkout api.
  //     }
  //   }
  //   set_submit_loading(false);
  // }

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   const cardElement = elements.getElement(CardElement);

  //   const clientSecret = payment_intent?.client_secret;

  //   const { error, paymentMethod } = await stripe.createPaymentMethod(clientSecret,{
  //     type: 'card',
  //     card: cardElement,
  //     billing_details: {
  //       name: name,
  //       address: {
  //         line1: address1,
  //         line2: address2,
  //         city: address_city,
  //         state: address_state,
  //         postal_code: address_postal,
  //         country: address_country,
  //       },
  //     },
  //   });

  //   if (error) {
  //     console.log('[error]', error);
  //   } else {
  //     console.log('[PaymentMethod]', paymentMethod);
  //     // Apply discount logic or send it to the server for processing
  //   }
  // };

  const validateFields = () => {
    let errors = {};
    if (!name) errors.name = "Please Enter Full Name";
    if (!address1) errors.address1 = "Please Enter Address";
    if (!address_city) errors.address_city = "Please Enter City";
    if (!address_state) errors.address_state = "Please Enter State";
    if (!address_postal) errors.address_postal = "Please Enter Zip/Postal Code";
    if (!address_country) errors.address_country = "Please Enter Country";

    set_errors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateFields()) {
      set_submit_loading(true);
      set_card_error("");

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const clientSecret = payment_intent?.client_secret;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: name,
        address: {
          line1: address1,
          line2: address2,
          city: address_city,
          state: address_state,
          postal_code: address_postal,
          country: address_country,
        },
      },
    });

    if (error) {
      set_card_error(error.message)
      console.log('[error]', error);
    } else {
      set_card_error("")

      console.log('[PaymentMethod]', paymentMethod);

      // After successfully creating the payment method, you typically confirm the payment using the clientSecret
      const { error: confirmError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      });

      if (confirmError) {
       // console.log('[confirmError]', confirmError);
      } else {
       if(paymentIntent.status === 'succeeded')
        {
          CALL_STRIPE_REGISTRATION_CHECKOUT();
           // here we call checkout api.
        }
        //console.log('[PaymentIntent]', paymentIntent);
        // Apply discount logic or send it to the server for processing
      }
    }
  }
  };

  const handleApplyCoupon = async () => {
    set_loaderCoupon(true);
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('amount', payment_amount);
    FORM_DATA.append('payment_intent_id', payment_intent?.id);
    FORM_DATA.append('coupon', couponCode);
    const STRIPE_PAYMENT_COUPON_API_RESPONSE = await STRIPE_PAYMENT_COUPON(FORM_DATA);
    console.log("line no. 83", STRIPE_PAYMENT_COUPON_API_RESPONSE);
    if (STRIPE_PAYMENT_COUPON_API_RESPONSE?.data?.status) {
      set_loaderCoupon(false);
      console.log("line no. 85", STRIPE_PAYMENT_COUPON_API_RESPONSE);
      // if (result.error) {
      //   console.error(result.error);
      //   setCoupon(null);
      // } else {
      //   console.log('Coupon applied:', result.coupon);
      //   setCoupon(result.coupon);
      // }

    }
    else {
      set_loaderCoupon(false);
    }
  };

  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '16px',
        color: '#000000',
        '::placeholder': {
          color: '#000000',
        },
        border: '1px solid #000000',
        padding: '10px',
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  const CALL_STRIPE_REGISTRATION_CHECKOUT = async () => {
    setLoading(true);
    var USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('student_user_id', USER_DATA.id);
    FORM_DATA.append('payment_intent_id', payment_intent?.id);
    const CALL_STRIPE_REGISTRATION_CHECKOUT_API_RESPONSE = await STRIPE_REGISTRATION_CHECKOUT(FORM_DATA);
    if (CALL_STRIPE_REGISTRATION_CHECKOUT_API_RESPONSE?.data?.status) {
      console.log("line no.134", CALL_STRIPE_REGISTRATION_CHECKOUT_API_RESPONSE);
      USER_DATA.payment_status = 1;
      localStorage.setItem('sis_user_data', JSON.stringify(USER_DATA));
      window.location.reload();
      setLoading(false);
    }

    setLoading(false);
  }

  // Show loader until data is fetched
  if (loading) {
    return <SisLoader />;
  }

  return (
    <>
      {payment_status ?
        <>
          <div style={{ textAlign: 'center' }}>
            <Result
              icon={<SmileOutlined />}
              title="Thank you for your payment!"
              subTitle={`Your payment for Enrollment Application has been successfully processed.`}
              extra={
                <>
                  <Button style={{borderColor:"#b64141", color:"#b64141", height:"40px"}} type='primary' onClick={() => window.open(response_json?.receipt_url)} ghost>Open Receipt</Button>
                </>
              }
            />
            {/* <div style={{ marginTop: '20px' }}>
        <p>Enrollment ID: {}</p>
        <p>You will receive a confirmation email shortly with the details of your enrollment.</p>
      </div> */}
          </div>
          <br/>
       {/* button */}
       {/* <div className="row">
                <div className="col-1" style={{ marginRight: "15px" }}>
                    <div className="input-box">
                        <Button onClick={() => navigate("/formpreview")} type="primary">
                            Previous
                        </Button>
                    </div>
                </div>
            </div> */}
        </>
        :
        <>
          <div className='row' style={{ marginLeft: "10px" }}>
            <p>In order to submit an application to Lincoln University, please pay the required application fee.</p>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row" style={{ margin: "0" }}>
              <div className="col-8">
                <div className="input-box">
                  <label>
                    Do you have a Coupon Code?
                  </label>
                  <Input
                    value={couponCode}
                    placeholder="Enter coupon code"
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                  <span style={{ fontSize: "14px", color: "#626262", fontWeight: "500" }}>Please input your coupon code here (if you have), otherwise proceed to pay the Application Fee shown below.</span>
                </div>
              </div>
              {coupon && (
                <div>
                  <p>Coupon Applied: {coupon.id} - {coupon.amount_off} off</p>
                </div>
              )}
            </div>
            <div className="row" style={{ margin: "0" }}>
              <div className="col-2" style={{ marginRight: "15px" }}>
                <div className="input-box">
                  {loaderCoupon ? (
                    <>
                      <Button type="primary" className="lusisbtn" style={{width:"156px"}}>
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            />
                          }
                        />{" "}
                        {apply_coupon &&
                          "Apply Coupon"
                        }
                        {remove_coupon &&
                          "Remove Coupon"
                        }
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button type="primary" className="lusisbtn" style={{width:"156px"}}
                        onClick={() => {
                          if (apply_coupon) {
                            handleApplyCoupon();
                          }
                          if (remove_coupon) {
                            //  handleRemoveCoupon(); 
                          }
                        }}>
                        {apply_coupon &&
                          "Apply Coupon"
                        }
                        {remove_coupon &&
                          "Remove Coupon"
                        }
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <br />
            <div className='row' style={{ margin: "0" }}>
              <div className="col-6 first-letter-capitalize">
                <h3 style={{ color: "#626262" }}>Total Application Fee</h3>
                <h4 style={{ marginTop: "6px", color: "#626262" }}>{amount}</h4>
              </div>
            </div>
            <div>
              <br />
              <hr style={{ margin: "0 20px 0 10px" }} />
              <br />
              <div className="theme-content-left-head" style={{ marginLeft: "10px" }}>
                <h3>Billing Information</h3>
              </div>
              <br />
            </div>
            <div className='row' style={{ margin: "0px 10px" }}>
              <p style={{ fontSize: "15px", color: "#777", fontWeight: 700, lineHeight: "20px" }}><span style={{ color: "red", fontWeight: 600 }}>Note:</span> If you are paying your application fee via credit/debit card, make sure you have a authorized valid credit/debit card and have sufficient balance to cover the application fee. International students paying application fee via credit/debit card are encouraged to verify with bank that the credit/debit card being used has permissions to make an international transactions to cover the application fee. Other wise, please proceed on using the wire transfer options. The wire transfer information can be found <a style={{textDecoration:"underline"}} target='blank' href='https://admissions.lincolnuca.edu/fees/#wiretransfer'>HERE.</a></p>
            </div>
            <br />
            <div style={{ margin: "0 10px" }}>
              <label className="cus-label" style={{ textTransform: "none", fontSize: "14px" }}>
                Credit Card or Debit Card<i style={{ color: "red" }}>*</i>
              </label>
              <div className='row' style={{ margin: "10px 10px 0 0" }}>
                <div className='col-12'
                  style={{
                    border: isCardFocused ? '1px solid #dfa414' : '1px solid #ced4da',
                    borderRadius: '4px',
                    padding: '10px',
                  }}
                >
                  <CardElement
                    onFocus={() => setIsCardFocused(true)}
                    onBlur={() => setIsCardFocused(false)}
                    options={cardElementOptions} />
                </div>
                {card_error &&
                  <span style={{ color: "#F00" }}>{card_error}</span>
                }
              </div>
            </div>
            <br />
            <div style={{ margin: "0 10px" }}>
              <label className="cus-label" style={{ textTransform: "none", fontSize: "14px" }}>
                Name on Card<i style={{ color: "red" }}>*</i>
              </label>
              <div className='row' style={{ margin: "0px 10px 0 0" }}>
                <div className="col-12" style={{ padding: 0 }}>
                  <div className="input-box">
                    <Input
                      disabled={localStorage.getItem("payment_status") == 1}
                      value={name}
                      placeholder="Enter Your Name"
                      onChange={(e) => set_name(e.target.value)}
                    />
                     {errors.name && <div style={{ color: "red" }}>{errors.name}</div>}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div style={{ margin: "0 10px" }}>
              <label className="cus-label" style={{ textTransform: "none", fontSize: "14px" }}>
                Address<i style={{ color: "red" }}>*</i>
              </label>
              </div>
            <div className="row" style={{ margin: "10px 10px 0 0" }}>
              <div className="col-6">
                <div className="input-box">
                  <label htmlFor="address1">
                   Address 1
                  </label>
                  <Input
                    value={address1}
                    placeholder="Address Line 1"
                    onChange={(e) => set_address1(e.target.value)}
                  />
                   {errors.address1 && <div style={{ color: "red" }}>{errors.address1}</div>}
                </div>
              </div>
              <div className="col-6">
                <div className="input-box">
                  <label htmlFor="address2">
                   Address 2
                  </label>
                  <Input
                    value={address2}
                    placeholder="Address Line 2"
                    onChange={(e) => set_address2(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="row" style={{ margin: "0px 10px 0 0" }}>
              <div className="col-3">
                <div className="input-box">
                  <label htmlFor="city">
                    City
                  </label>
                  <Input
                    value={address_city}
                    placeholder="City"
                    onChange={(e) => set_address_city(e.target.value)}
                  />
                   {errors.address_city && <div style={{ color: "red" }}>{errors.address_city}</div>}
                </div>
              </div>
              <div className="col-3">
                <div className="input-box">
                  <label htmlFor="state">
                    State
                  </label>
                  <Input
                    value={address_state}
                    placeholder="State"
                    onChange={(e) => set_address_state(e.target.value)}
                  />
                  {errors.address_state && <div style={{ color: "red" }}>{errors.address_state}</div>}
                </div>
              </div>
              <div className="col-3">
                <div className="input-box">
                  <label htmlFor="postal">
                    Zip / Postal Code
                  </label>
                  <Input
                    value={address_postal}
                    placeholder="Zip/Postal"
                    onChange={(e) =>
                      set_address_postal(e.target.value)
                    }
                    onKeyDown={(event) => {
                      // Allow Backspace, Delete, Tab, Enter, Arrow keys
                      if (
                        event.key === 'Backspace' ||
                        event.key === 'Delete' ||
                        event.key === 'Tab' ||
                        event.key === 'Enter' ||
                        event.key === 'ArrowUp' ||
                        event.key === 'ArrowDown' ||
                        event.key === 'ArrowLeft' ||
                        event.key === 'ArrowRight'
                      ) {
                        return;
                      }

                      // Prevent non-numeric characters
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                   {errors.address_postal && <div style={{ color: "red" }}>{errors.address_postal}</div>}
                </div>
              </div>
               <div className="col-3">
                <div className="input-box">
                  <label htmlFor="country">
                    Country
                  </label>
                  <CountryDropdown
                    valueType="short"
                    id="mailingcountry"
                    style={{ width: "100%", height: "40px" }}
                    placeholde="--Select Country--"
                    value={address_country}
                    onChange={(value) => set_address_country(value)}
                    className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                  />
                  {errors.address_country && <div style={{ color: "red" }}>{errors.address_country}</div>}
                </div>
              </div>
            </div>
            <br />

            <div className="row"  style={{ display: "flex", marginLeft: "10px", gap: "20px" }}>
                <div className="input-box">
                  <Button onClick={() => navigate("/formpreview")} type="primary" className="lusisbtn">
                    Previous
                  </Button>
                </div>
                <div className="input-box">
                  {submit_loading || !stripe ? (
                    <>
                      <Button type="primary" className="lusisbtn">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            />
                          }
                        />{" "}
                        Pay
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button type="primary" htmlType="submit" className="lusisbtn">
                        Pay{" "}{amount}
                      </Button>
                    </>
                  )}
                </div>
            </div>
          </form>
        </>
      }
    </>

  );
};

export default CheckoutForm;
