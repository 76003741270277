import { Button, Select, Spin, notification } from "antd";
import AgreementPreview from "./Form Preview/agreementPreview";
import DemographicInformationPreview from "./Form Preview/demographicInformationPreview";
import FinanceDeclarationPreview from "./Form Preview/financeDeclarationPreview";
import PersonalInformationPreview from "./Form Preview/personalInformationPreview";
import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  GET_PERSONAL_INFORMATION,
  PROGRAM_CONCENTARTION_UPDATE,
  STUDENT_PROGRAMS_LIST,
  GO_TO_DASHBOARD,
} from "../../../apis/apis";
import SisLoader from "../../../widgets/loader";

const FormPreview = () => {
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [school_decision, set_school_decision] = useState("");
  const [programme_intended, set_programme_intended] = useState("");
  const [programme_list, set_programme_list] = useState([]);
  const [program_concentration, set_program_concentration] = useState([]);
  const [selected_program_concentration, set_selected_program_concentration] =
    useState("");
  const [
    students_programmes_registrations,
    set_students_programmes_registrations,
  ] = useState(0);
  const [
    programmes_registrations_process_status,
    set_programmes_registrations_process_status,
  ] = useState(0);

  const [
    programmes_registrations_complete_status,
    set_programmes_registrations_complete_status,
  ] = useState(0);
  const [payment_status, set_payment_status] = useState("");
  const [school_decision_status, set_school_decision_status] = useState("");
  const [
    concentration_available_for_program,
    set_concentration_available_for_program,
  ] = useState("");
  const [concen_selected, set_concen_selected] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const local_payment = JSON.parse(localStorage.getItem("sis_user_data"));
    set_payment_status(local_payment?.payment_status);
    VIEW_PERSONAL_INFORMATION();
    PROGRAMS_LIST_API();
  }, []);

  const PROGRAMS_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await STUDENT_PROGRAMS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_programme_list(API_RESPONSE?.data?.programmes);
    } else {
      set_loader(false);
    }
  };

  const VIEW_PERSONAL_INFORMATION = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("student_user_id", USER_DATA.id);
    const VIEW_PERSONAL_INFORMATION_API_RESPONSE =
      await GET_PERSONAL_INFORMATION(FORM_DATA);
    if (VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.status) {
      const PERSONAL_DETAILS_DATA =
        VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.data?.personal_details;
      const PERSONAL_CONTACT_DETAILS_DATA =
        VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.data?.contact_details;
      set_school_decision(PERSONAL_DETAILS_DATA?.school_decision);
      set_school_decision_status(
        VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.data
          ?.school_decision_status
      );
      set_concentration_available_for_program(
        VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.data
          ?.concentration_available_for_program
      );
      set_program_concentration(
        VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.data?.concentration
      );
      set_students_programmes_registrations(
        PERSONAL_DETAILS_DATA?.students_programmes_registrations
      );
      set_programmes_registrations_process_status(
        PERSONAL_DETAILS_DATA?.programmes_registrations_process_status
      );
      set_programmes_registrations_complete_status(
        VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.data?.personal_details?.programmes_registrations_complete_status
      );
      set_programme_intended(PERSONAL_DETAILS_DATA?.programme_intended);
      set_concen_selected(
        VIEW_PERSONAL_INFORMATION_API_RESPONSE?.data?.data?.personal_details?.concen_selected == 1 ? true : false);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  const SAVE_PROGRAM_CONCENTARTION = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("student_user_id", btoa(USER_DATA.id));
    FORM_DATA.append("program_concentration", selected_program_concentration ?? "");
    const SAVE_PROGRAM_CONCENTARTION_API_RESPONSE =
      await PROGRAM_CONCENTARTION_UPDATE(FORM_DATA);
    if (SAVE_PROGRAM_CONCENTARTION_API_RESPONSE?.data?.status) {
      set_loader(false);
      VIEW_PERSONAL_INFORMATION();
    } else {
      set_loader(false);
      set_errors(SAVE_PROGRAM_CONCENTARTION_API_RESPONSE?.data?.errors);
    }
  };

  const MOVE_TO_DASHBOARD = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("student_user_id", USER_DATA.id);
    const MOVE_TO_DASHBOARD_API_RESPONSE = await GO_TO_DASHBOARD(FORM_DATA);
    if (MOVE_TO_DASHBOARD_API_RESPONSE?.data?.status) {
      localStorage.clear();
      notification.open({
        message: "Success!!",
        description:
          "Now You are a Lusis Student and login with same credentials.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      window.location = "/";
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  return (
    <>
      {!payment_status && (
        <>
          <div className="row">
            <div className="online-form-step-box">
              <div className={"online-form-step"} onClick={() => navigate("/")}>
                <div className="online-form-step-line"></div>
                <span>1</span>
                <h3>Basic Information </h3>
              </div>
              <div
                className={"online-form-step"}
                onClick={() => navigate("/demographic-information")}
              >
                <div className="online-form-step-line"></div>
                <span>2</span>
                <h3>Demographic Information</h3>
              </div>
              <div
                className={"online-form-step"}
                onClick={() => navigate("/finance-declaration")}
              >
                <div className="online-form-step-line"></div>
                <span>3</span>
                <h3>Finance Declaration</h3>
              </div>
              <div
                className={"online-form-step"}
                onClick={() => navigate("/agreement")}
              >
                <div className="online-form-step-line"></div>
                <span>4</span>
                <h3>Agreement</h3>
              </div>
              <div className={"online-form-step running"}>
                <div className="online-form-step-line"></div>
                <span>5</span>
                <h3>Preview</h3>
              </div>
              <div
                className={"online-form-step"}
                onClick={() => navigate("/payment")}
              >
                <span>6</span>
                <h3>Payment</h3>
              </div>
            </div>
          </div>
        </>
      )}

      {loader ? (
        <SisLoader />
      ) : (
        <>
          {school_decision_status ? (
            <>
              {/* {programmes_registrations_process_status === 1 ? ( */}
              {programmes_registrations_complete_status ? (
                <>
                  <div className="row" style={{ marginLeft: 0 }}>
                    <div className="theme-content-left-head">
                      <h2>Online Application</h2>
                      <br />
                      <p>
                        You are enrolled in the{" "}
                        <strong>
                          "
                          {programme_list?.length > 0
                            ? programme_list?.find(
                                (item) => item.id === programme_intended
                              )?.title || ""
                            : ""}
                          "
                        </strong>{" "}
                        program.
                      </p>
                      <br />
                      <div className="row">
                        <div className="col-1">
                          <div className="input-box">
                            {loader ? (
                              <>
                                <Button
                                  type="primary"
                                  className="lusisbtn"
                                  style={{ width: "192px" }}
                                >
                                  <Spin
                                    indicator={
                                      <LoadingOutlined
                                        style={{
                                          fontSize: "12px",
                                          color: "#fff",
                                          marginRight: "5px",
                                        }}
                                      />
                                    }
                                  />{" "}
                                  Go to Dashboard
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  onClick={MOVE_TO_DASHBOARD}
                                  type="primary"
                                  className="lusisbtn"
                                  style={{ width: "192px" }}
                                >
                                  Go to Dashboard
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                </>
              ) : (
                <>
                  {!payment_status && (
                    <>
                      <br />
                      <hr />
                      <br />
                    </>
                  )}
                  <div className="row" style={{ marginLeft: 0 }}>
                    <div className="theme-content-left-head">
                      <h2>Online Application</h2>
                      <br />
                      <div
                        className="attendance-summary"
                        style={{ maxWidth: "400px" }}
                      >
                        <div
                          className="attendance-item"
                          style={{ width: "100%" }}
                        >
                          <div className="attendance-label first-letter-capitalize">
                            <h2 style={{ color: "#626262" }}>
                              Application Status
                            </h2>
                          </div>
                          <div className="attendance-value">
                            <h3>{school_decision}</h3>
                            <p>
                              Congratulations your application has been
                              accepted.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                      {concentration_available_for_program ?
                      <>
                      {concen_selected ? 
                      <>
                      <h3 style={{ color: "#626262" }}>
                      You will be contacted by our admissions team with further details.
                       </h3>
                        <br/>
                        <h3 style={{ color: "#000" }}>
                      Please do not hesitate to contact our admissions team at admissions@lincolnuca.edu or call 510-628-8010 for any questions or concerns.
                        </h3>
                      </>
                      :
                       (
                        <>
                          <div className="row">
                            <div className="input-box col-5">
                              <label>
                                Program Concentration
                                {/* <i style={{ color: "red" }}>*</i> */}
                              </label>
                              <br></br>
                              <Select
                                placeholder="--Select Program Concentration--"
                                style={{ width: "100%" }}
                                onChange={(value) => {
                                  set_selected_program_concentration(value);
                                }}
                                //  disabled={program_concentration.length === 0}
                                value={selected_program_concentration}
                              >
                                {program_concentration &&
                                  program_concentration?.map((item) => {
                                    if (
                                      item.status === 1 &&
                                      item.soft_delete === 0
                                    ) {
                                      return (
                                        <Select.Option
                                          key={item.id}
                                          value={item.id}
                                        >
                                          {item.title}
                                        </Select.Option>
                                      );
                                    }
                                  })}
                              </Select>
                              {errors?.program_concentration && (
                                <>
                                <br/><br/>
                                  <span style={{ color: "red" }}>
                                    {errors?.program_concentration[0]}
                                  </span>
                                </>
                              )}
                            </div>
                            <div
                              className="col-1"
                              style={{ marginRight: "15px", marginTop: "25px" }}
                            >
                              <div className="input-box">
                                {loader ? (
                                  <>
                                    <Button type="primary" className="lusisbtn">
                                      <Spin
                                        indicator={
                                          <LoadingOutlined
                                            style={{
                                              fontSize: "12px",
                                              color: "#fff",
                                              marginRight: "5px",
                                            }}
                                          />
                                        }
                                      />{" "}
                                      Save
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      onClick={SAVE_PROGRAM_CONCENTARTION}
                                      type="primary"
                                      className="lusisbtn"
                                    >
                                      Save
                                    </Button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                        
                      )}</> : (
                        <h3 style={{ color: "#626262" }}>
                         You will be contacted by our admissions team with further details.
                         Please do not hesitate to contact our admissions team at admissions@lincolnuca.edu or call 510-628-8010 for any questions or concerns.
                        </h3>
                      )}
                    </>
              )}
            </>
          ) : (
            <>
              <PersonalInformationPreview />
              <DemographicInformationPreview />
              <FinanceDeclarationPreview />
              <AgreementPreview />
            </>
          )}
          <br />
          <br />
          {/* button */}
          {!payment_status && (
            <>
              <div
                className="row"
                style={{ display: "flex", margin: 0, gap: "20px" }}
              >
                <div className="input-box">
                  {loader ? (
                    <>
                      <Button type="primary" className="lusisbtn">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            />
                          }
                        />{" "}
                        Previous
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => navigate("/agreement")}
                        type="primary"
                        className="lusisbtn"
                      >
                        Previous
                      </Button>
                    </>
                  )}
                </div>
                <div className="input-box">
                  {loader ? (
                    <>
                      <Button type="primary" className="lusisbtn">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            />
                          }
                        />{" "}
                        Next
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => navigate("/payment")}
                        type="primary"
                        className="lusisbtn"
                      >
                        Next
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FormPreview;
